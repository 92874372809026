<template>
    <div>
        <div style="display: flex; gap: 1rem; margin: 1rem">
            <!--<button class="download" @click.prevent="download">Download</button>-->
            <button class="print" @click.prevent="print">Preview</button>
            <b-button class="actionBut" variant="dark"   v-b-modal.send-modal>
                <!--<i class="ri-printer-line align-middle" ></i>-->
                Send
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-forward" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" /> <path d="M3 6l9 6l9 -6" /> <path d="M15 18h6" /> <path d="M18 15l3 3l-3 3" /> </svg>
                <b-modal hide-footer id="send-modal" title="Send By Mail" title-class="font-18" ref="send-modal">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="email"
                                   required
                                   type="email"
                                   placeholder="Email"
                                   class="form-control"
                            />
                        </div>
                        <div>
                            <input type="file" accept="application/pdf" @change="chooseFile"/>
                        </div>
                        <div v-if="error" style="margin-top: 20px; color: red">
                            <label>{{error}}</label>
                        </div>
                    </div>

                    <div style="display: flex; justify-content: flex-end; margin-top: 10px">
                        <b-button @click="send">Send</b-button>
                    </div>
                </b-modal>
            </b-button>
        </div>
        <div style="display: flex; justify-content: space-around">
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :paginate-elements-by-height="1125"
                :paginate-elements-by-width="1125"
                :enable-download="true"
                :preview-modal="true"
                :filename="site.site.site_name"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-content-width="1125px"
                pdf-content-height="1125px"
                :htmlToPdfOptions="{
                    margin: [0, 0],
                    filename: site.site.site_name,
                    html2canvas:  { scale: 2 },
                    jsPDF: {
                        format: 'a4',
                        orientation: 'landscape'
                    }
                }"
                pdf-format="a4"
                pdf-orientation="landscape"
                ref="html2Pdf"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)"
            >
            
            <div class="body" slot="pdf-content">
                <div class="body__container">
                    <div class="container">
                        <h1>Point-of-Care Technology<br>
                            Continuous Quality Improvement<br>
                            for Early Infant Diagnosis Program
                        </h1>
                        <h3><i>Proudly awards</i></h3>
                        <h1><b>{{site.site.site_name}}</b></h1>
                        <h3>For outstanding performance in the {{ptround.year}}, <br>POCT-CQI EID proficiency testing program for HIV-1 EARLY INFANT DIAGNOSIS
                            <!-- proficiency testing program for hiv-1 early infant diagnosis  -->
                        </h3>
                        <div class="container__footer">
                            <div>
                                <hr>
                                    <span>PROF. PATRICK NJUKENG<br>
                                        EXECUTIVE DIRECTOR<br>
                                        GLOBAL HEALTH SYSTEMS SOLUTIONS
                                    </span>
                                </div>
                                <div>
                                    <hr>
                                    <span>{{ptround.month}}, {{ptround.year}} SESSION
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
import { axios_get, download_url, url_notification } from '../../../helpers/helper';
import axios from 'axios';
import VueHtml2pdf from 'vue-html2pdf'
export default {
    components: {
        VueHtml2pdf
    },
    data(){
        return{
            site:null,
            ptround:null,
            select:null,
            printPdf:false,
            fichier: null,
        }
    },

    mounted(){
        axios_get("/enrollment/"+this.$route.params.id, {}, (d)=>{
            console.log(d)
            this.site = d
            axios_get("/pt_rounds/"+d.pt_round_id, {}, (da)=>{
                this.ptround = da.ptround
                console.log(this.ptround)
            }, (e)=>{
                console.log('error', e)
            }, ()=>{
                console.log('finish')
            })
        }, (e)=>{
            console.log('error', e)
        }, ()=>{
            console.log('finish')
        })
    },

    methods:{
        chooseFile(e){
            this.fichier = e.target.files[0]
            console.log(this.fichier)
        },
        print(){
            this.$refs['html2Pdf'].generatePreviewPdf()
        },
        download(){
            console.log("dowload")
            this.$refs['html2Pdf'].generatePdf()
        },
        send(){
            if (this.fichier === null || this.email===null){
                return;
            }
            this.error = null
            const formdata = new FormData();
            formdata.append('file', this.fichier)
            axios.post(download_url, formdata).then(res=>{
                console.log(res)
                const data = {
                    "to": this.email,
                    "subject": "GHSS Proficiency Testing Software",
                    "html":"<h1>Pass Certificate </h1>" +
                        "<p>Votre certification est disponible sur ce lien/Your certificate is available via this link: "+res.data.ressource+"</p>",
                }
                axios.get(url_notification+"/email", {params: data}).then(rep=>{
                    console.log(rep)
                    this.$refs['send-modal'].hide()
                }).catch(err=>{
                    console.log(err)
                })
            }).catch(err=>{
                console.log(err)
                this.error = "Veuillez réessayer"
            }).finally(()=>{
                console.log("finally")
            })
        },
        async beforeDownload ({ html2pdf, options=this.setOptions, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                console.log(pdf)
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    //pdf.setPage(i)
                    //pdf.setFontSize(10)
                    //pdf.setTextColor(150)
                    //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
    }
}
</script>

<style lang="scss">
    @import '../../../assets/scss/certification.scss';
</style>